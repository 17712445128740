import { motion } from "framer-motion";

const PersonTile = ({ person }) => {

  if (!person) {
    return null;
  }

  return (

      <motion.div
        className={`bg-white p-2 lg:p-4 flex flex-col lg:flex-row max-w-[330px] h-[23rem] lg:h-96 mx-auto`}
        layout
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.4 }}

      >
        <motion.div>
          <div className="relative">
          <motion.img className="mx-auto" src={'/media/creators/' + person.image} alt={person.name} width={300} height={300} />
          <motion.p
                    transition={{ delay: 0.4, duration: 0.3 }}
                    className="absolute -bottom-px -left-px bg-white text-lg uppercase px-4 border-2 border-white">{person.role}</motion.p>
          </div>
          <div className="flex items-center pt-1 w-full">
            <p className="text-black w-full text-center text-2xl font-light"  style={{fontFamily: 'CaviarDreams'}}>{person.name}</p>
          </div>
        </motion.div>
      </motion.div>
  );
}

export default PersonTile;
import { motion } from "framer-motion";

const Team = () => {
  return (
    <>
      <div className="w-full h-16 lg:h-24 bg-black"></div>
      <div className="bg-black text-white pt-2 pb-10">
        <div className="container mx-auto max-w-5xl">
          <p className="text-center text-balance text-xl md:text-2xl lg:text-3xl px-4">The strength of Tasty Collective lies in our experienced and communicative team. This ensures smooth collaboration and outstanding results for every project.</p>
        </div>
      </div>
      <div className="min-h-screen bg-white pt-4 lg:pt-10">
        <div className="container mx-auto max-w-5xl flex flex-col justify-center text-center text-balance px-4">
          <motion.div
            className="py-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src="/media/creators/bkomorowski.jpg" alt="Bartłomiej Komorowski" className="w-80 lg:w-96 mx-auto" />
            <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-thin py-2" style={{ fontFamily: 'CaviarDreams' }}>Bartłomiej Komorowski</p>
            <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">founder</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Years of work in the advertising industry allowed me to learn about market trends, the needs of clients and advertising agencies, and encouraged me to establish Tasty.</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Tasty collective is a production studio specializes in comprehensive production of advertising films, table top, food and beauty photography for TV and digital advertising.</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Thanks to its team of creators, Tasty provides clients and advertising agencies with support in the implementation of projects from pre-production, production, post-production to broadcast material.</p>
          </motion.div>
          <motion.div
            className="py-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src="/media/creators/mossowska.jpg" alt="Monika Ossowska" className="w-80 lg:w-96 mx-auto" />
            <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-thin py-2" style={{ fontFamily: 'CaviarDreams' }}>Monika Ossowska</p>
            <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">executive producer</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">A graduate of the Faculty of Organization of Film Art at the National Higher School of Film, Television and Theater. L. Schiller in Łódź. Over the last few years, she has worked with production houses such as Dobro, Dynamo, Busy Bee, Highnoon, F25, Madants, Apple Film Production, Prime Time, Graffiti Films, 24/7 X Radioaktive.</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Despite many years of work in the advertising market, she is often involved in feature productions. The film "The Dress", which she was an executive producer at Studio Dobro, was nominated for an Oscar in 2022, and the film "Silent Twins" directed by Agnieszka Smoczyńska was distinguished in the Un Certain Regard competition at the Cannes Festival.</p>
          </motion.div>
          <motion.div
            className="py-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src="/media/creators/dliszewski.jpg" alt="Darek Liszewski" className="w-80 lg:w-96 mx-auto" />
            <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-thin py-2" style={{ fontFamily: 'CaviarDreams' }}>Darek Liszewski</p>
            <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">SFX supervisor/production coordinator</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">In the Tasty Collective team, I contribute on many levels, from production planning and implementation to technical and design solutions for SFX. My passion in its current form was born in the 1990s when I was involved in designing and constructing various types of film lighting used in numerous advertising projects.</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">The experience I gained allowed me to master the technique of high-speed cinematography, a skill that brings me great satisfaction.</p>
          </motion.div>
          <motion.div
            className="py-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src="/media/creators/mthiem.jpg" alt="Maciej Thiem" className="w-80 lg:w-96 mx-auto" />
            <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-thin py-2" style={{ fontFamily: 'CaviarDreams' }}>Maciej Thiem</p>
            <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">motion control</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Motion Control Partner, producer, and creative technologist with over 10 years of experience in the film and television industry. He has completed projects for clients such as ZDF, RTL, TVP, Canal+, and Discovery Channel.</p>
            <p className="py-2 text-sm md:text-base lg:text-lg xl:text-xl font-thin">Inspired by the tech scene in Berlin. CEO at motioncontrol.berlin.</p>
          </motion.div>
        </div>
      </div>
    </>
  );
}
export default Team;
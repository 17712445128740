import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);

  const toggleNav = () => setOpenNav(!openNav);

  const links = [
    { title: "About Us", path: "/about" },
    { title: "What we do", path: "/portfolio" },
    { title: "Creators", path: "/creators" },
    { title: "Team", path: "/team" },
    { title: "SFX", path: "/sfx" },
    { title: "Studio", path: "/studio" },
    { title: "Contact", path: "/contact" }
  ];

  useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth > 1024 && setOpenNav(false));
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <nav
        className={"transition duration-150 ease-in-out bg-transparent fixed z-40 min-w-full py-2 md:py-6 px-4 md:px-8 xl:px-8"}
        style={{fontFamily: 'CaviarDreams'}}
      >
        <div className="mx-auto flex justify-between items-center tracking-widest font-bold">
          <a href="/">
            <img src='/logo.png' alt='logo' className='max-w-44 lg:max-w-64 2xl:max-w-72' />
          </a>
          <motion.button className="text-white text-2xl p-2 w-12 lg:hidden" whileHover={{ scale: 1.1 }} onClick={toggleNav}><FontAwesomeIcon icon="fa-solid fa-bars" /></motion.button>
          <ul className="hidden lg:flex items-center text-[0.7rem] 2xl:text-base ml-1 text-white uppercase text-nowrap">
            {links.map((link, index) => (
              <li key={index}><Link to={link.path} className="transition duration-300 ease-in-out py-2 px-1 lg:px-2 2xl:px-4 text-nowrap hover:bg-white hover:text-black">{link.title}</Link></li>
            ))}
            <li><Link to="https://vimeo.com/user161890495" target="_blank" className="transition duration-300 ease-in-out py-2 px-1 lg:px-2 2xl:px-2 text-nowrap lg:text-lg xl:text-2xl hover:bg-white hover:text-black"><FontAwesomeIcon icon="fa-brands fa-vimeo-v" /></Link></li>
            <li><Link to="https://www.instagram.com/collectivetasty/" target="_blank" className="transition duration-300 ease-in-out py-2 px-1 lg:px-2 2xl:px-2 text-nowrap lg:text-lg xl:text-2xl hover:bg-white hover:text-black"><FontAwesomeIcon icon="fa-brands fa-instagram" /></Link></li>
          </ul>
        </div>
        <AnimatePresence>
          {
            openNav && (
              <motion.div
                initial={{ x: 1000, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute right-0 top-0 bg-black/75 text-white w-screen h-screen items-stretch z-30"
              >
                <div className="h-screen flex-col pt-24" onClick={toggleNav}>
                  <ul className="text-xl">
                    {links.map((link, index) => (
                      <li key={index} className="p-4 text-center" onClick={scrollToTop}><Link to={link.path}>{link.title}</Link></li>
                    ))}
                    <li className="p4 text-center"><Link to="https://vimeo.com/user161890495" target="_blank" className=""><FontAwesomeIcon icon="fa-brands fa-vimeo-v" /></Link></li>
                    <li className="p-4 text-center"><Link to="https://www.instagram.com/collectivetasty/" target="_blank" className=""><FontAwesomeIcon icon="fa-brands fa-instagram" /></Link></li>
                  </ul>
                </div>
              </motion.div>
            )
          }
        </AnimatePresence>
      </nav>
    </>
  );
}
export default Navbar;
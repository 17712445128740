import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const videoRef = useRef();
  const [isMobile, setIsMobile] = useState(null);
  const [videoUrl, setVideoUrl] = useState("/media/home/main-bg.mp4");

  useEffect(() => {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);

    window.addEventListener("resize", () => {
      window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
    });
  }, []);

  useEffect(() => {
    isMobile ? setVideoUrl("/media/home/main-bg-mobile.mp4") : setVideoUrl("/media/home/main-bg.mp4");
  }, [isMobile]);

  useEffect(() => {
    videoRef.current.load();
  }, [videoUrl]);

  return (
    <div>
      <div className="relative w-full h-dvh overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-dvh bg-cover">
          <video autoPlay loop muted playsInline ref={videoRef} key={videoUrl} className="w-full h-full object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <source src={videoUrl} type="video/mp4" />
          </video>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-xl lg:text-2xl 2xl:text-3xl text-center p-6">
            <p className="hidden md:block text-nowrap pb-8">Stunning Visuals that Taste as Good as They Look</p>
            <Link to='https://vimeo.com/962131386' className="text-white p-2 border-2 text-lg lg:text-xl 2xl:text-2xl px-8 pb-3 hover:bg-white/50">Showreel</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;

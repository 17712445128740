import './App.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Creators from './pages/Creators';
import Team from './pages/Team';
import SFX from './pages/SFX';
import Studio from './pages/Studio';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Showreel from './pages/Showreel';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="creators" element={<Creators />} />
        <Route path="team" element={<Team />} />
        <Route path="sfx" element={<SFX />} />
        <Route path="studio" element={<Studio />} />
        <Route path="contact" element={<Contact />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="showreel" element={<Showreel />} />
      </Route>
    </Routes>
  );
}

export default App;

import { motion } from "framer-motion";

const Contact = () => {
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  }
  const fadeInDuration = 2.0;
  const fadeInDelay = 0.2;
  const fadeInMultiplier = 0.4;

  return (
    <div className="relative w-full min-h-screen overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-screen bg-cover">
          <video autoPlay loop muted playsInline className="w-full h-full object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <source src='/media/contact/contact-hero.mp4' type="video/mp4" />
          </video>
        </div>
      <div className="absolute w-full min-h-screen bg-black/50 text-white pt-16 md:pt-24 xl:pt-48 px-6">
        <div className="container mx-auto text-center text-balance text-base md:text-2xl lg:text-3xl">
          <motion.div {...animation} transition={{ duration: fadeInDuration, delay: 0 }}>
            <p className="py-4">Tasty collective as a brand provides advertising agencies, production houses and clients with comprehensive shooting days. Our collective is committed to every single project from the creative phase all the way to pre-production, production and post-production.</p>
            <p className="py-4 md:my-6">Contact us!</p>
          </motion.div>
          <motion.div {...animation} transition={{ duration: fadeInDuration, delay: fadeInDelay + fadeInMultiplier * 1 }}>
            <a className="font-bold" href="mailto:hello@collectivetasty.com"  style={{ fontFamily: 'CaviarDreams' }}>hello@collectivetasty.com</a>
            <p className="mb-8">general support</p>
          </motion.div>
          <motion.div {...animation} transition={{ duration: fadeInDuration, delay: fadeInDelay + fadeInMultiplier * 2 }}>
            <a className="font-bold" href="mailto:office.collectivetasty@gmail.com"  style={{ fontFamily: 'CaviarDreams' }}>office.collectivetasty@gmail.com</a>
            <p className="mb-8">production suppoprt</p>
          </motion.div>
          <motion.div {...animation} transition={{ duration: fadeInDuration, delay: fadeInDelay + fadeInMultiplier * 3 }}>
            <a className="font-bold" href="mailto:darek.tasty@gmail.com"  style={{ fontFamily: 'CaviarDreams' }}>darek.tasty@gmail.com</a>
            <p className="mb-8">SFX/technical support</p>
          </motion.div>
          <motion.div {...animation} transition={{ duration: fadeInDuration, delay: fadeInDelay + fadeInMultiplier * 4 }}>
            <p className="py-4">Let’s meet for a coffee and have a chat about your idea, vision or concept.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
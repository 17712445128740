import { motion } from "framer-motion";

const Studio = () => {
  return (
    <div className="bg-white">
      <div className="relative w-full h-[80dvh] overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 w-full h-screen bg-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <video autoPlay loop muted playsInline className="w-full h-full object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <source src='/media/studio/studio-hero.mp4' type="video/mp4" />
          </video>
        </motion.div>
      </div>
      <div className="">
        <motion.div
          className="container mx-auto p-2 my-4 xl:my-6 2xl:my-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <p className="text-lg md:text-xl lg:text-2xl text-center text-balance px-6">Welcome to our studio! It's a spacious and versatile place where we film our commercials. With an 8-meter high ceiling, and measuring 22 meters long by 12 meters wide, we have plenty of room to create all kinds of amazing content. Whether you're working on a small tabletop shoot or a larger production, our studio is ready to accommodate your needs.</p>
        </motion.div>
        <motion.div
          className="container mx-auto max-w-4xl pb-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.2 }}
        >
          <img src="/media/studio/studio-1.png" alt="Studio 1" className="w-full object-cover" />
        </motion.div>
      </div>
    </div>
  );
}

export default Studio;
import { motion } from "framer-motion"

const About = () => {
  return (
    <div className="bg-white">
      <div className="relative w-full h-[80dvh] overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-[80dvh] bg-cover">
          <video autoPlay loop muted playsInline className="w-full h-full object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <source src="/media/about/about-hero.mp4" type="video/mp4" />
          </video>
          <div className="absolute top-1/2 left-0 -translate-y-1/2 w-full">
            <motion.div className="container max-w-screen-lg mx-auto text-white text-lg lg:text-xl 2xl:text-2xl text-center text-balance p-6">
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5, delay: 0.8 }}
                className="">Tasty Collective is a team united by a shared passion for creativity and excellence</motion.p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">

        <motion.div
          className="text-black my-8 xl:my-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-lg md:text-xl lg:text-2xl text-center text-balance px-6">
            Tasty is a production studio specializing in table, food, and beauty photography for TV and digital advertising. We love creating beautiful content that shows off your brand in the best light.
          </p>
        </motion.div>

        <div className="px-8 md:px-10 lg:px-16 xl:px-6 2xl:px-14">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <video autoPlay loop muted playsInline className="w-full">
              <source src="/media/about/bts-yellowoz.mp4" type="video/mp4" />
            </video>
          </motion.div>
        </div>

        <motion.div
          className="text-black my-8 xl:my-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-lg md:text-xl lg:text-2xl text-center text-balance px-6">
            Our team has years of experience and a keen eye for detail. Our spacious studio is equipped with all the latest technology and tools, so we can handle a variety of projects, big or small.
          </p>
        </motion.div>

        <div className="px-8 md:px-10 lg:px-16 xl:px-6 2xl:px-14">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <video autoPlay loop muted playsInline className="w-full">
              <source src="/media/about/bts-ysl.mp4" type="video/mp4" />
            </video>
          </motion.div>
        </div>

        <motion.div
          className="text-black my-8 xl:my-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-lg md:text-xl lg:text-2xl text-center text-balance px-6">
            We believe in working closely with our clients to understand their vision and goals. Whether you're looking to highlight the details of a dish or showcase a product, we're here to help bring your ideas to life.
          </p>
        </motion.div>

        <motion.div
          className="text-black my-4 xl:my-6 2xl:my-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-lg md:text-xl lg:text-3xl font-bold text-center text-balance px-6 pt-6">
            Let's work together and create something Tasty!
          </p>
        </motion.div>

      </div>
      <div className="w-full h-16"></div>
    </div>
  );
}
export default About;
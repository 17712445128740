import { motion } from "framer-motion";
import SFXTile from "../components/SFXTile";

const SFX = () => {
  const loadAnimation = {
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    transition: { duration: 0.5, delay: 0.4 }
  }

  return (
    <div className="bg-white">
      <div className="relative w-full h-[80dvh] overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-[80dvh] bg-cover">
          <video autoPlay loop muted playsInline className="w-full h-full object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <source src="/media/sfx/sfx-hero.mp4" type="video/mp4" />
          </video>
          <div className="absolute top-1/2 left-0 -translate-y-1/2 w-full">
            <motion.div className="container max-w-screen-lg mx-auto text-white text-xl lg:text-2xl 2xl:text-3xl text-center text-balance p-6">
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5, delay: 0.8 }}
                className="">We use Phantom high-speed cameras and custom rigs, all perfectly synchronized with motion control.</motion.p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="">
        <motion.div className="container mx-auto py-2 px-6 md:px-2 xl:py-6" {...loadAnimation}>
          <p className="text-lg md:text-xl lg:text-2xl text-center text-balance">At Tasty Collective, we have a well-equipped workshop filled with machines, rigs, and devices needed to create original special effects shots on set. Whether it's high-speed filming or practical effects, our team has the tools and know-how to bring your ideas to life.</p>
        </motion.div>
        <div className="container 2xl:max-w-full 2xl:w-11/12 mx-auto px-8 md:px-10 lg:px-16 xl:px-6 2xl:px-14 grid grid-cols-1 xl:grid-cols-3">
          <motion.div {...loadAnimation}>
            <SFXTile video={{
              title: 'Behind The Scenes - DIA',
              vimeoID: '951239164',
              thumbnail: 'bts-dia.jpg'
            }} />
          </motion.div>
          <motion.div {...loadAnimation}>
            <SFXTile video={{
              title: 'Behind The Scenes - Chocolate',
              vimeoID: '854636398',
              thumbnail: 'bts-chocolate.png'
            }} />
          </motion.div>
          <motion.div {...loadAnimation}>
            <SFXTile video={{
              title: 'Behind The Scenes - YSL',
              vimeoID: '941556518',
              thumbnail: 'bts-ysl.jpg'
            }} />
          </motion.div>
        </div>
      </div>
      <div className="w-full h-12 "></div>
    </div>
  );
}
export default SFX;
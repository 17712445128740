import { useEffect, useState } from "react";
import { motion } from "framer-motion"
import PortfolioTile from "../components/PortfolioTile";

const Portfolio = () => {
  const [videos, setVideos] = useState([]);

  const showreel = {
    "id": 0,
    "title": "Showreel",
    "thumbnail": "prfthmb-reel.jpg",
    "vimeoID": "962131386"
  }

  useEffect(() => {
    fetch("/portfolio.json")
      .then(response => response.json())
      .then(data => setVideos(data));
  }, []);

  return (
    <div className="bg-white pb-10">
      <div className="h-16 lg:h-24 bg-black"></div>
      <div className="container mx-auto w-full min-h-40 h-full">
        <motion.div
          className="lg:mx-40 mt-10 mb-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <PortfolioTile video={showreel} />
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2">

          {videos.map((video, idx) => (
            <motion.div
              key={video.id}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 + (idx % 2 === 0 ? 0 : 0.1) }}
            >
              <PortfolioTile key={video.id} video={video} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Portfolio;
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { useEffect, useState } from "react";

const Layout = () => {
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > window.innerHeight * 0.05 ? setScrolledDown(true) : setScrolledDown(false);
    });
  }, []);


  return (
    <div className="bg-black" style={{ fontFamily: 'Poppins' }}>
      <Navbar />
      <Outlet />
      <div className={"w-full h-16 lg:h-24 fixed top-0 transition duration-300 ease-in-out z-30 " + (scrolledDown ? " bg-black/50" : "")}></div>
    </div>
  );
}
export default Layout;
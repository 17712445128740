import { useEffect, useState } from "react";
import PersonTile from "../components/PersonTile";
import { AnimatePresence, motion } from "framer-motion";

const Creators = () => {
  const [creators, setCreators] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(0);
  const fadeInDelay = 0.1
  const fadeInDelayRange = 0.3

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  const outerAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  }

  const innerAnimation = {
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    transition: { duration: 0.5, delay: fadeInDelay }
  }

  useEffect(() => {
    fetch("/creators.json")
      .then(response => response.json())
      .then(data => setCreators(data));
  }, []);

  useEffect(() => {
    if (selected) {
      setSelectedPosition(window.scrollY + window.innerHeight / 8);
    }
  }, [selected]);

  const handleSelect = (person) => {
    if (selected === person) {
      setSelected(null);
      return;
    }
    setSelectedPosition(window.scrollY + window.innerHeight / 4)
    setSelected(person);
  }

  return (
    <>
      <div className="bg-black h-16 lg:h-24"></div>
      <div className="min-h-screen bg-white text-black pt-8 lg:pt-20">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 md:max-w-2xl lg:grid-cols-3 lg:max-w-5xl gap-0 lg:gap-4 mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          {creators && creators.map((creator, index) => (
            <motion.div {...outerAnimation} transition={{ duration: 0.5, delay: 0.3 + Math.random() * fadeInDelayRange }} key={index}>
              <motion.div
                onClick={() => handleSelect(creator)}
                {...innerAnimation}
              >
                <PersonTile person={creator} />
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div >


      <AnimatePresence>
        {selected && (
          <motion.div
            className="w-4/5 md:w-full pb-20 max-w-3xl bg-white absolute left-0 right-0 mx-auto z-50"
            style={{ top: selectedPosition }}
            onClick={() => setSelected(null)}
            exit={{ opacity: 0 }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div>
                <div className="relative">
                  <motion.p
                    {...animation}
                    transition={{ delay: 0.4, duration: 0.3 }}
                    className="absolute bottom-0 left-0 bg-white text-lg md:text-2xl uppercase px-4 border border-white">{selected.role}</motion.p>
                  <motion.img
                    {...animation}
                    transition={{ delay: 0.4 }}
                    src={'/media/creators/' + selected.image}
                    alt={selected.name}
                    className="w-full  h-96 pr-0 mx-auto md:pr-3 object-cover"
                  />
                </div>
              </div>
              <div className="flex flex-col px-4 md:px-0">
                <motion.p
                  {...animation}
                  transition={{ delay: 0.2 }}
                  className="text-2xl md:text-3xl  2xl:text-4xl mt-4 mb-1 font-light px-1">{selected.name}
                </motion.p>
                <motion.div
                  {...animation}
                  transition={{ delay: 0.3 }}
                  className="flex flex-col justify-center"
                >
                  {selected.links.map((link, index) => (
                    <motion.a key={index} href={'https://' + link} className="text-md md:text-lg 2xl:text-xl hover:bg-black hover:text-white px-1 mr-1">{link}</motion.a>
                  ))}
                </motion.div>
                <motion.div
                  {...animation}
                  transition={{ delay: 0.6 }}
                >
                  {selected.bio.split('\n\n').map((paragraph, index) => (
                    <p key={index} className="text-xs md:text-sm 2xl:text-base mt-4 font-light px-1">{paragraph}</p>
                  ))}
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>


      <AnimatePresence>
        {selected && (
          <motion.div
            className="w-screen h-full bg-black/50 fixed top-0 left-0"
            onClick={() => setSelected(null)}
          >
          </motion.div>
        )}
      </AnimatePresence>


    </>
  );
}
export default Creators;
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ReactPlayer from "react-player"

const PortfolioTile = ({ video }) => {
  const [hovered, setHovered] = useState(false);
  const [playing, setPlaying] = useState(false);

  const handleHover = () => {
    setHovered(true);
    // setPlaying(true);
  }

  const handleUnhover = () => {
    setHovered(false);
    setPlaying(false);
  }

  return (
    <div className="block p-6 bg-white">
      <motion.div
        whileHover={{ scale: 1.2 }}
        transition={{ duration: 0.4 }}
        className="relative w-full pt-[56.25%] overflow-hidden hover:z-20"
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
        <div className={`transition duration-300 ease-in-out absolute top-0 left-0 w-full h-full`}>
          <ReactPlayer
            url={`https://vimeo.com/${video.vimeoID}`}
            controls
            width={'100%'}
            height={'100%'}
            playing={playing}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
          />
        </div>
        <AnimatePresence>
          {!hovered && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="transition duration-300 ease-in-out absolute top-0 left-0 w-full h-full "
            >
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <p className="text-white text-xl lg:text-2xl xl:text-3xl">{video.title}</p>
              </div>
              <img className="w-full h-full object-cover" src={`/media/portfolio/${video.thumbnail}`} alt={video.title} />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  )
}

export default PortfolioTile;